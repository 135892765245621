import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { SearchBarMenuOptions } from '@/components/molecules/SearchBarMenuOptions/SearchBarMenuOptions';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem/DropdownMenuItem';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { stringMatch } from '../../../lib/searchAlgorithms';
import {
  DropdownMenuGroupType,
  DropdownMenuOptionType,
  DropdownMenuProps,
} from '../DropdownMenu/DropdownMenu';
import { SearchBar, SearchBarProps } from '../SearchBar/SearchBar';

interface SearchBarWithMenuProps
  extends Omit<SearchBarProps, 'value' | 'onChange'>,
    Omit<DropdownMenuProps, 'value' | 'onChange' | 'options'> {
  currentVectorSearch?: string;
  handleVectorSearch?: (input: string) => void;
  limitSuggestions?: boolean;
  setInput: (input: string) => void;
  input: string;
  showOptions: boolean;
  setShowOptions: (open: boolean) => void;
  searchBarVariant?: 'dialog' | 'section';
}
export const SearchBarWithMenu = ({
  input = '',
  setInput,
  models = {},
  makes = [],
  recentSearch = [],
  placeholder,
  currentVectorSearch,
  handleVectorSearch = () => {},
  limitSuggestions = true,
  setShowOptions,
  showOptions,
  searchBarVariant = 'section',
}: SearchBarWithMenuProps) => {
  const [dropdownOptions, setDropdownOptions] = useState<
    DropdownMenuOptionType[] | DropdownMenuGroupType[]
  >([]);
  const [reversedRecentSearch, setReversedRecentSearch] =
    useState<string[]>(recentSearch);

  useEffect(() => {
    if (currentVectorSearch === '') {
      setInput('');
    }
  }, [currentVectorSearch, setInput]);

  useEffect(() => {
    if (recentSearch && recentSearch.length > 0) {
      setReversedRecentSearch(recentSearch);
    }
  }, [recentSearch]);

  const getSuggestions = useCallback(
    (
      newInput: string,
      makesList: string[],
      modelsList: { [key: string]: string[] }
    ) => {
      let finalMenuItems = [];

      finalMenuItems = stringMatch(
        newInput.toLowerCase(),
        makesList,
        modelsList
      );

      if (limitSuggestions) {
        finalMenuItems = finalMenuItems.slice(0, 5);
      }

      setDropdownOptions(finalMenuItems);
    },
    [limitSuggestions]
  );

  const handleChangeInput = useCallback(
    (newInput: string) => {
      setInput(newInput);
      getSuggestions(newInput, makes, models);
    },
    [makes, models, setInput, getSuggestions]
  );

  const handleDropdownSelect = useCallback(
    (selected: string) => {
      setInput(selected);
      handleVectorSearch(selected);
    },
    [setInput, handleVectorSearch]
  );

  const handleEnter = useCallback(
    (val: string | undefined) => {
      if (
        val !== '' &&
        val != null &&
        input !== currentVectorSearch &&
        showOptions
      ) {
        handleVectorSearch(input);
      }
      setShowOptions(false);
    },
    [
      currentVectorSearch,
      handleVectorSearch,
      input,
      showOptions,
      setShowOptions,
    ]
  );

  const containerClassName = clsx('flex h-full w-full flex-col', {
    'px-l pt-m': searchBarVariant === 'dialog',
  });

  return (
    <div className={containerClassName}>
      <div className="flex items-center gap-s">
        {searchBarVariant === 'dialog' && (
          <div className="cursor-pointer">
            <ChevronLeftIcon
              className="size-xl"
              onMouseDown={() => setShowOptions(false)}
            />
          </div>
        )}
        <SearchBar
          value={input}
          onChange={handleChangeInput}
          placeholder={placeholder}
          onEnter={handleEnter}
          onFocus={() => setShowOptions(true)}
          onBlur={() => handleEnter(input)}
        />
      </div>
      {showOptions && (
        <SearchBarMenuOptions
          autoCompleteSuggestions={dropdownOptions}
          onChange={(val: string[]) => {
            handleDropdownSelect(val[0]);
          }}
          variant={DropdownMenuItemVariants.NoSelect}
          reversedRecentSearch={reversedRecentSearch}
          setReversedRecentSearch={setReversedRecentSearch}
          searchBarVariant={searchBarVariant}
        />
      )}
    </div>
  );
};
