import FormCheckbox from '@/components/molecules/FormCheckbox';
import { SearchFilterGeneralProps } from '../SearchFilterGeneralInfoSection/SearchFilterGeneralInfoSection';

const sellerTypeOptions = [
  { label: 'Franchise Dealers', value: 'Franchise' },
  { label: 'Independent Dealers', value: 'Independent' },
];

export const SearchFilterSellerTypeSection: React.FC<
  SearchFilterGeneralProps
> = ({ updateFilter, filters }) => {
  return (
    <section className="flex flex-col gap-s pt-s">
      {sellerTypeOptions.map((option, index) => {
        return (
          <FormCheckbox
            key={index}
            className="flex w-full items-center justify-start rounded-small bg-neutral-100 px-l py-m hover:bg-neutral-200"
            checkBoxStyle="w-[20px] h-[20px] border-1 rounded-xsmall"
            onChange={(evt) => {
              let sellerTypeArray = filters.dealer_group
                ? filters.dealer_group.split(',')
                : [];
              if (evt) {
                sellerTypeArray.push(option.value);
              } else {
                sellerTypeArray = sellerTypeArray.filter(
                  (seller) => seller !== option.value
                );
              }
              updateFilter({
                fields: {
                  dealer_group: sellerTypeArray.join(','),
                },
              });
            }}
            checked={filters.dealer_group.includes(option.value)}
            checkboxLabel={option.label ? option.label : ''}
          />
        );
      })}
    </section>
  );
};
