import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import useWindowSize from '@/hooks/useWindowSize';
import { useRouter } from 'next/router';
import Head from 'next/head';

export interface PaginationRangesProps {
  count: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  size?: 'small' | 'medium' | 'large';
  spacing?: number;
  defaultPage?: number;
  color?: 'standard' | 'primary' | 'secondary';
  handlePreviousPage: () => void;
  handleNextPage: () => void;
}

interface CustomButtonProps {
  page: number;
  count: number;
  onClick?: () => void;
  children: React.ReactNode;
}

const CustomButton = ({
  page,
  count,
  onClick,
  children,
}: CustomButtonProps) => {
  return (
    <div
      className={`flex items-center justify-center gap-xs ${page === count ? 'invisible' : ''}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const PaginationRanges: React.FC<PaginationRangesProps> = ({
  count,
  onChange,
  size = 'medium',
  defaultPage = 1,
  color = 'standard',
  page,
  handlePreviousPage,
  handleNextPage,
}) => {
  const { isMobile } = useWindowSize();
  const router = useRouter();

  const generatePageHref = (pageNumber: number) => {
    return router.asPath.includes('/page/')
      ? router.asPath.replace(/\/page\/\d+/, `/page/${pageNumber}`)
      : `${router.asPath}/page/${pageNumber}`;
  };
  return (
    <div className="flex w-full flex-col items-center justify-center gap-m">
      <Head>
        {page > 1 && <link rel="prev" href={generatePageHref(page - 1)} />}
        {page < count && <link rel="next" href={generatePageHref(page + 1)} />}
      </Head>
      <div className="flex w-full justify-between m:hidden">
        <CustomButton page={page} onClick={handlePreviousPage} count={1}>
          <ChevronLeftIcon stroke="black" width={20} height={20} />
          <span className="text-body1Regular text-brandMidnight">Previous</span>
        </CustomButton>
        <CustomButton page={page} count={count} onClick={handleNextPage}>
          <span className="text-body1Regular text-brandMidnight">Next</span>
          <ChevronRightIcon stroke="black" width={20} height={20} />
        </CustomButton>
      </div>
      <Pagination
        aria-label="pagination"
        count={count}
        boundaryCount={isMobile ? 1 : 2}
        onChange={onChange}
        size={size}
        defaultPage={defaultPage}
        color={color}
        page={page}
        renderItem={(item) => {
          return (
            <PaginationItem
              {...item}
              component="a"
              href={generatePageHref(item.page || 1)}
              rel={item.type === 'previous' ? 'prev' : 'next'}
              onClick={(e) => {
                e.preventDefault();
                onChange(e, item.page || 1);
              }}
              className={` ${item.type === 'page' ? '!mx-[2px] l:!mx-[6px]' : ''} ${item.type === 'previous' || item.type === 'next' ? '!hidden m:!flex' : ''} `}
              slots={{
                previous: () => (
                  <div className="hidden m:flex">
                    <CustomButton page={page} count={1}>
                      <ChevronLeftIcon stroke="black" width={20} height={20} />
                      <span className="text-body1Regular text-brandMidnight">
                        Previous page
                      </span>
                    </CustomButton>
                  </div>
                ),
                next: () => (
                  <div className="hidden m:flex">
                    <CustomButton page={page} count={count}>
                      <span className="text-body1Regular text-brandMidnight">
                        Next page
                      </span>
                      <ChevronRightIcon stroke="black" width={20} height={20} />
                    </CustomButton>
                  </div>
                ),
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default PaginationRanges;
