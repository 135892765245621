import { RecentSearch } from '@/components/organisms/RecentSearch/RecentSearch';
import clsx from 'clsx';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem/DropdownMenuItem';
import DropdownMenu, {
  DropdownMenuGroupType,
  DropdownMenuOptionType,
  DropdownMenuProps,
} from '../DropdownMenu/DropdownMenu';

interface SearchBarMenuOptionsProps
  extends Omit<DropdownMenuProps, 'value' | 'options'> {
  searchBarVariant: 'dialog' | 'section';
  autoCompleteSuggestions: DropdownMenuOptionType[] | DropdownMenuGroupType[];
  setReversedRecentSearch: (reversedRecentSearch: string[]) => void;
  reversedRecentSearch: string[];
}

export const SearchBarMenuOptions = ({
  autoCompleteSuggestions,
  onChange,
  reversedRecentSearch,
  setReversedRecentSearch,
  variant = DropdownMenuItemVariants.SingleSelect,
  showCount = true,
  searchBarVariant,
}: SearchBarMenuOptionsProps) => {
  const dropDownMenuStyles = clsx({
    'max-h-none bg-neutral-100 shadow-none': searchBarVariant === 'dialog',
  });
  return (
    <div className="z-20 h-full w-full">
      {autoCompleteSuggestions.length !== 0 ? (
        <DropdownMenu
          options={autoCompleteSuggestions}
          value={[]}
          onChange={onChange}
          variant={variant}
          className={dropDownMenuStyles}
        />
      ) : (
        <RecentSearch
          showCount={showCount}
          variant={variant}
          onChange={onChange}
          reversedRecentSearch={reversedRecentSearch}
          setReversedRecentSearch={setReversedRecentSearch}
          searchBarVariant={searchBarVariant}
        />
      )}
    </div>
  );
};
