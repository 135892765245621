import { DropdownMenuOptionType } from '@/components/molecules/DropdownMenu';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import FormSelect from '@/components/molecules/FormSelect';
import { SearchFilterGeneralProps } from '../SearchFilterGeneralInfoSection/SearchFilterGeneralInfoSection';

interface SearchFilterFeatureSectionProps extends SearchFilterGeneralProps {
  features: DropdownMenuOptionType[];
}

export const SearchFilterFeatureSection: React.FC<
  SearchFilterFeatureSectionProps
> = ({ updateFilter, filters, features }) => {
  // TODO: Add sections (tech, safety, etc. ) to features, for now its just the list
  return (
    <>
      <section>
        <FormSelect
          label="Features"
          placeholder="Select Feature(s)"
          disabled={features.length === 0}
          variant={DropdownMenuItemVariants.MultiSelect}
          options={features}
          value={filters.features}
          showCount={false}
          onChange={(value) => {
            updateFilter({
              fields: {
                features: value,
              },
            });
          }}
        />
      </section>
    </>
  );
};
