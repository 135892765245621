import { Input, InputProps } from '@/components/atoms/Input/Input';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';

export interface SearchBarProps
  extends Pick<InputProps, 'placeholder' | 'onChange' | 'onFocus' | 'onBlur'> {
  value?: string;
  onEnter?: (input: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  value = '',
  onChange,
  placeholder = 'Search by make or model',
  onEnter,
  onFocus,
  onBlur,
}) => {
  return (
    <div className="flex-grow">
      <Input
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onEnter?.(value);
          }
        }}
        aria-label="Search bar"
        id="search-bar"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete="off"
        prepend={MagnifyingGlassIcon}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

/**
 * new search bar component that is only the input
 * - pass in props for focus, blur, onChange. Used to get suggestions and control
 *    state of dropdown visibility
 * search bar with options that combines the search bar and menu options dropdown
 * - used on homepage and vlp
 * dialog search bar -> uses search bar and adds a back button + padding and menu options dropdown
 * - navbar search
 */
