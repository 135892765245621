import FormCheckbox from '@/components/molecules/FormCheckbox';
import { useVehicleFilterSettingsContext } from '@/context/VehicleFilterSettings';

export const SearchFilterEVSavingsSection: React.FC = () => {
  const {
    settings: vehicleFilterSettings,
    handleSetDisplayIncentives,
    handleSetDisplayFuelSavings,
  } = useVehicleFilterSettingsContext();

  return (
    <section className="flex flex-col gap-l pt-s">
      <section className="flex flex-col gap-s">
        <div className="text-microLight text-neutral-800">
          Select to deduct from the vehicle’s listed price.
        </div>
        <FormCheckbox
          className="flex w-full items-center justify-start rounded-small bg-neutral-100 px-l py-m hover:bg-neutral-200"
          checkBoxStyle="w-[20px] h-[20px] border-1 rounded-xsmall"
          onChange={(evt) => {
            handleSetDisplayIncentives(evt);
          }}
          checked={vehicleFilterSettings.displayIncentives}
          checkboxLabel="Incentives & rebates"
        />

        <FormCheckbox
          className="flex w-full items-center justify-start rounded-small bg-neutral-100 px-l py-m hover:bg-neutral-200"
          checkBoxStyle="w-[20px] h-[20px] border-1 rounded-xsmall"
          onChange={(evt) => {
            handleSetDisplayFuelSavings(evt);
          }}
          checked={vehicleFilterSettings.displayFuelSavings}
          checkboxLabel="Gas savings (estimate)"
        />
      </section>
    </section>
  );
};
