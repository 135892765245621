import { useEffect, useState } from 'react';
import { validateUSZipCode } from '@/lib/validators';
import { DEFAULT_US_ZIP_CODE, LOCATION_KEY } from '@/lib/constants';

export const useZipcode = () => {
  const [zipcode, setZipcode] = useState<string>();
  const [verifiedZipcode, setVerifiedZipcode] = useState<string>();
  // verify zipcode matches format and save it to verifiedZipcode
  useEffect(() => {
    const currentRegion =
      process.env.NEXT_PUBLIC_DOMAIN === 'EV.ca' ? 'CA' : 'US';

    if (zipcode !== verifiedZipcode) {
      if (currentRegion !== 'US') {
        setVerifiedZipcode(zipcode);
        return;
      } // only validate US zipcodes
      if (zipcode && validateUSZipCode(zipcode)) {
        setVerifiedZipcode(zipcode);
        localStorage.setItem(LOCATION_KEY, zipcode);
      }
    }
  }, [verifiedZipcode, zipcode]);

  const region = process.env.NEXT_PUBLIC_DOMAIN === 'EV.ca' ? 'CA' : 'US';

  useEffect(() => {
    const savedLocation = localStorage.getItem(LOCATION_KEY);
    if (!savedLocation) {
      const fallbackZipcode =
        process.env.NEXT_PUBLIC_DOMAIN === 'EV.ca' ? '' : DEFAULT_US_ZIP_CODE;
      setZipcode(fallbackZipcode);
    } else {
      setZipcode(savedLocation);
    }
  }, []);
  return { zipcode, setZipcode, verifiedZipcode, region };
};
