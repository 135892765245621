import RangeSlider, { SizeVariants } from '@/components/atoms/RangeSlider';
import { Switcher } from '@/components/atoms/Switcher/Switcher';
import FormInput from '@/components/molecules/FormInput';
import PriceRange from '@/components/molecules/PriceRange';
import useWindowSize from '@/hooks/useWindowSize';
import {
  calculatedMonthlyPaymentFinance,
  calculateMSRPFinance,
} from '@/lib/calculatedMonthlyPayment';
import {
  CALC_FINANCE_LOAN_TERM_OPTIONS,
  CALC_INITIAL_FINANCE_TERM_INDEX,
} from '@/lib/constants';
import { formatNumberWithCommas } from '@/lib/formatNumberWithCommas';
import { isValidFormattedPrice } from '@/lib/isValidFormattedPrice';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';
import {
  SearchFilterGeneralProps,
  SearchFilterPriceProps,
} from '../SearchFilterGeneralInfoSection/SearchFilterGeneralInfoSection';

interface SearchFilterPriceSectionProps extends SearchFilterGeneralProps {
  isListedPrice: boolean;
  setIsListedPrice: (isListedPrice: boolean) => void;
}
interface ListedPriceSectionProps extends SearchFilterGeneralProps {
  settings: SearchFilterPriceProps;
  handleSetMinPrice: (value: number) => void;
  handleSetMaxPrice: (value: number) => void;
}
interface MaxMonthlyPaymentSectionProps extends SearchFilterGeneralProps {
  settings: SearchFilterPriceProps;
  handleSetMonthly: (value: number) => void;
  handleSetMaxPrice: (value: number) => void;
}

const ListedPriceSection: React.FC<ListedPriceSectionProps> = ({
  updateFilter,
  settings,
  handleSetMinPrice,
  handleSetMaxPrice,
}) => {
  const { isDesktop } = useWindowSize();

  const { minPrice, maxPrice } = settings;

  const defaultSliderValue = useMemo(
    () => [String(minPrice ?? 0), String(maxPrice ?? 250000)],
    [maxPrice, minPrice]
  );
  const [sliderValues, setSliderValues] = useState(defaultSliderValue);
  const [debouncedSliderValues] = useDebounce(sliderValues, 800);
  const skipDebounce = useRef(true);

  useEffect(() => {
    if (!minPrice && !maxPrice) {
      setSliderValues(defaultSliderValue);
    }
  }, [defaultSliderValue, maxPrice, minPrice]);

  useEffect(() => {
    if (skipDebounce.current === false) {
      handleSetMinPrice(Math.round(Number(debouncedSliderValues[0])));
      handleSetMaxPrice(Math.round(Number(debouncedSliderValues[1])));

      updateFilter({
        fields: {
          price_start: debouncedSliderValues[0],
          price_end: debouncedSliderValues[1],
        },
      });
      skipDebounce.current = true;
    }
  }, [
    debouncedSliderValues,
    updateFilter,
    handleSetMinPrice,
    handleSetMaxPrice,
  ]);

  const handleInputChange = (price: string, index: number) => {
    skipDebounce.current = true;
    const { formattedPrice, isValid } = isValidFormattedPrice(price);
    if (isValid) {
      const updatedValues = [...sliderValues];
      updatedValues[index] = formattedPrice;
      setSliderValues(updatedValues);
      updateFilter({
        fields: {
          [index === 0 ? 'price_start' : 'price_end']: formattedPrice,
        },
        refetchOnUpdate: false,
      });
    }
  };

  const handleInputBlur = (index: number) => {
    handleSetMinPrice(Math.round(Number(sliderValues[0])));
    handleSetMaxPrice(Math.round(Number(sliderValues[1])));
    updateFilter({
      fields: {
        [index === 0 ? 'price_start' : 'price_end']: sliderValues[index],
      },
    });
  };

  return (
    <section className="flex flex-col gap-xl">
      <PriceRange
        minValue={formatNumberWithCommas(sliderValues[0])}
        maxValue={formatNumberWithCommas(sliderValues[1])}
        minLabel="Min. Price"
        maxLabel="Max. Price"
        showSlider={false}
        handleMinInput={(price_start) => handleInputChange(price_start, 0)}
        handleMaxInput={(price_end) => handleInputChange(price_end, 1)}
        handleMinBlurChange={() => handleInputBlur(0)}
        handleMaxBlurChange={() => handleInputBlur(1)}
      />
      <RangeSlider
        min={0}
        max={250000}
        step={1000}
        defaultValue={[0, 250000]}
        values={sliderValues.map(Number)}
        onValueChange={(values) => {
          skipDebounce.current = false;
          setSliderValues(values.map(String));
        }}
        size={isDesktop ? SizeVariants.Desktop : SizeVariants.Mobile}
      />
    </section>
  );
};

const MaxMonthlyPaymentSection: React.FC<MaxMonthlyPaymentSectionProps> = ({
  updateFilter,
  settings,
  handleSetMonthly,
}) => {
  const { maxPrice, monthlyPayment } = settings;

  const labelComponent = (
    <div className="flex items-center justify-between">
      <span className="text-body2Regular">Max. Monthly payment</span>
    </div>
  );

  return (
    <FormInput
      label={labelComponent}
      type="text"
      prepend={monthlyPayment ? '$' : ''}
      placeholder="$3000/mo"
      value={String(monthlyPayment || '')}
      onChange={(value) => {
        handleSetMonthly(Number(value));
      }}
      onBlur={() => {
        updateFilter({
          fields: {
            price_end: String(maxPrice),
          },
        });
      }}
    />
  );
};

type Action =
  | { type: 'SET_MIN_PRICE'; payload: number }
  | { type: 'SET_MAX_PRICE'; payload: number }
  | { type: 'SET_MONTHLY_PAYMENT'; payload: number };

const initialState: SearchFilterPriceProps = {
  minPrice: null,
  maxPrice: null,
  monthlyPayment: null,
};
const settingsReducer = (
  state: SearchFilterPriceProps,
  action: Action
): SearchFilterPriceProps => {
  switch (action.type) {
    case 'SET_MIN_PRICE': {
      const newMax = Math.max(state.maxPrice || 0, action.payload);
      const newMonthly = Math.round(
        calculatedMonthlyPaymentFinance(
          0,
          newMax,
          Number(
            CALC_FINANCE_LOAN_TERM_OPTIONS[CALC_INITIAL_FINANCE_TERM_INDEX]
          )
        )
      );
      return {
        minPrice: action.payload,
        maxPrice: newMax,
        monthlyPayment: newMonthly,
      };
    }
    case 'SET_MAX_PRICE': {
      const newMonthly = Math.round(
        calculatedMonthlyPaymentFinance(
          0,
          action.payload,
          Number(
            CALC_FINANCE_LOAN_TERM_OPTIONS[CALC_INITIAL_FINANCE_TERM_INDEX]
          )
        )
      );
      return {
        minPrice: Math.min(state.minPrice || 0, action.payload),
        maxPrice: action.payload,
        monthlyPayment: newMonthly,
      };
    }
    case 'SET_MONTHLY_PAYMENT': {
      const newMonthly = Math.min(3000, action.payload);
      const newMSRP = Math.round(
        calculateMSRPFinance(
          newMonthly,
          0,
          Number(
            CALC_FINANCE_LOAN_TERM_OPTIONS[CALC_INITIAL_FINANCE_TERM_INDEX]
          ),
          0,
          0,
          0
        )
      );

      return {
        minPrice: Math.min(state.minPrice || 0, newMSRP),
        maxPrice: newMSRP,
        monthlyPayment: Math.round(newMonthly),
      };
    }

    default:
      return initialState;
  }
};

export const SearchFilterPriceSection: React.FC<
  SearchFilterPriceSectionProps
> = ({ updateFilter, filters, isListedPrice, setIsListedPrice }) => {
  const [settings, dispatch] = useReducer(settingsReducer, initialState);

  const handleSetMinPrice = useCallback((value: number) => {
    dispatch({ type: 'SET_MIN_PRICE', payload: value });
  }, []);
  const handleSetMaxPrice = useCallback((value: number) => {
    dispatch({ type: 'SET_MAX_PRICE', payload: value });
  }, []);
  const handleSetMonthly = useCallback((value: number) => {
    dispatch({ type: 'SET_MONTHLY_PAYMENT', payload: value });
  }, []);

  return (
    <section className="flex flex-col gap-l pt-s">
      <section className="flex flex-col gap-s">
        <Switcher
          aria-label="Price displayed as Listed Price or Monthly Payment toggle"
          value={isListedPrice ? 'Listed' : 'Monthly'}
          options={[
            {
              text: 'Listed',
              value: 'Listed',
            },
            {
              text: 'Monthly',
              value: 'Monthly',
            },
          ]}
          onChange={(evt) => {
            if (evt === 'Listed') {
              setIsListedPrice(true);
            } else {
              setIsListedPrice(false);
            }
          }}
        />
      </section>
      {isListedPrice ? (
        <ListedPriceSection
          updateFilter={updateFilter}
          filters={filters}
          settings={settings}
          handleSetMinPrice={handleSetMinPrice}
          handleSetMaxPrice={handleSetMaxPrice}
        />
      ) : (
        <MaxMonthlyPaymentSection
          updateFilter={updateFilter}
          filters={filters}
          settings={settings}
          handleSetMaxPrice={handleSetMaxPrice}
          handleSetMonthly={handleSetMonthly}
        />
      )}
    </section>
  );
};
