import {
  Switcher,
  SwitcherVariants,
} from '@/components/atoms/Switcher/Switcher';
import { Squares2X2Icon } from '@heroicons/react/24/outline';
import { QueueListIcon } from '@heroicons/react/24/solid';

interface ListGridViewToggleProps {
  gridViewEnabled: boolean;
  setIsGridView: (isGridView: boolean) => void;
}

export const ListGridViewToggle: React.FC<ListGridViewToggleProps> = ({
  gridViewEnabled,
  setIsGridView,
}) => {
  return (
    <section>
      <Switcher
        aria-label="List or Gallery view toggle"
        variant={SwitcherVariants.Icon}
        value={gridViewEnabled ? 'Gallery' : 'List'}
        options={[
          {
            value: 'Gallery',
            icon: {
              icon: <Squares2X2Icon className="h-[20px] w-[20px]" />,
              position: 'right',
            },
          },
          {
            value: 'List',
            icon: {
              icon: <QueueListIcon className="h-[20px] w-[20px]" />,
              position: 'right',
            },
          },
        ]}
        onChange={(evt) => {
          setIsGridView(evt === 'Gallery');
        }}
      />
    </section>
  );
};
