import FormCheckbox from '@/components/molecules/FormCheckbox';
import Label from '@/components/atoms/legacy/Label';
import FormInput from '@/components/molecules/FormInput';
import { formatNumberWithCommas } from '@/lib/formatNumberWithCommas';
import { isValidFormattedPrice } from '@/lib/isValidFormattedPrice';
import { SearchFilterGeneralProps } from '../SearchFilterGeneralInfoSection/SearchFilterGeneralInfoSection';

const conditionOptions = [
  { label: 'New', value: 'New' },
  { label: 'Pre-owned', value: 'Used' },
];

const fuelTypeOptions = [
  { label: 'Battery Electric Vehicle (EV)', value: 'Electric' },
  { label: 'Plug-in Hybrid (PHEV)', value: 'Hybrid' },
];

export const SearchFilterConditionAndFuelSection: React.FC<
  SearchFilterGeneralProps
> = ({ updateFilter, filters }) => {
  const { maxMileage } = filters;
  return (
    <section className="flex flex-col gap-l pt-s">
      <section className="flex flex-col gap-s">
        <Label>
          <span className="block">Vehicle title</span>
        </Label>
        {conditionOptions.map((option, index) => {
          return (
            <FormCheckbox
              key={index}
              className="flex w-full items-center justify-start rounded-[8px] bg-neutral-100 px-l py-m hover:bg-neutral-200"
              checkBoxStyle="w-[20px] h-[20px] border-1 rounded-xsmall"
              onChange={(evt) => {
                let conditionArray = filters.condition
                  ? filters.condition.split(',')
                  : [];
                if (evt) {
                  conditionArray.push(option.value);
                } else {
                  conditionArray = conditionArray.filter(
                    (condition) => condition !== option.value
                  );
                }
                updateFilter({
                  fields: {
                    condition: conditionArray.join(','),
                  },
                });
              }}
              checked={filters.condition.includes(option.value)}
              checkboxLabel={option.label}
            />
          );
        })}
      </section>
      <section className="flex flex-col gap-s">
        <Label>
          <span className="block">Fuel type</span>
        </Label>
        {fuelTypeOptions.map((option, index) => {
          return (
            <FormCheckbox
              key={index}
              className="flex w-full items-center justify-start rounded-[8px] bg-neutral-100 px-l py-m hover:bg-neutral-200"
              checkBoxStyle="w-[20px] h-[20px] border-1 rounded-xsmall"
              onChange={(evt) => {
                let fuelTypeArray = filters.fuelType
                  ? filters.fuelType.split(',')
                  : [];
                if (evt) {
                  fuelTypeArray.push(option.value);
                } else {
                  fuelTypeArray = fuelTypeArray.filter(
                    (fuelType) => fuelType !== option.value
                  );
                }
                updateFilter({
                  fields: {
                    fuelType: fuelTypeArray.join(','),
                  },
                });
              }}
              checked={filters.fuelType.includes(option.value)}
              checkboxLabel={option.label}
            />
          );
        })}
      </section>
      <section>
        <FormInput
          label="Max. Mileage"
          type="text"
          placeholder="100,000 miles"
          value={formatNumberWithCommas(maxMileage)}
          onChange={(value) => {
            const { formattedPrice, isValid } = isValidFormattedPrice(value);
            if (isValid) {
              updateFilter({
                fields: {
                  maxMileage: formattedPrice,
                },
                refetchOnUpdate: false,
              });
            }
          }}
          onBlur={() => {
            const adjustedMileage =
              Number(maxMileage) >= 1 && Number(maxMileage) < 100
                ? '100'
                : maxMileage;
            updateFilter({
              fields: {
                maxMileage: adjustedMileage,
              },
            });
          }}
        />
      </section>
    </section>
  );
};
