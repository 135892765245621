const copyToClipboard = (str: string) => {
  const dummy = document.createElement('input');

  document.body.appendChild(dummy);
  dummy.value = str;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

export default copyToClipboard;
