import React from 'react';
import { ScComponent } from '@/types/interfaces';
import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import TextButton from '@/components/atoms/TextButton/TextButton';

export interface EmptySearchResultProps extends ScComponent {
  variant: 'noResults' | 'failedSearch';
  headerText: string;
  bodyText?: string;
  handleResetAction: () => void;
  handleExpandSearchAction?: () => void;
  'aria-label': string;
}

const EmptySearchResult: React.FC<EmptySearchResultProps> = ({
  variant,
  handleResetAction,
  handleExpandSearchAction,
  headerText,
  bodyText,
  'aria-label': ariaLabel,
}) => {
  return (
    <article
      className={`flex flex-col items-center rounded-small pb-3xl text-center`}
    >
      <div className="flex h-full flex-col items-center justify-center">
        <h2 className="text-h4Regular text-neutral-800">{headerText}</h2>
        {variant === 'noResults' && (
          <div className="flex items-center justify-center gap-s">
            <TextButton
              onClick={handleExpandSearchAction}
              aria-label={ariaLabel}
            >
              Search Nationwide
            </TextButton>
            <div className="h-xl border-[0.5px] border-neutral-400"></div>
            <TextButton onClick={handleResetAction} aria-label={ariaLabel}>
              Reset filters
            </TextButton>
          </div>
        )}
        {variant === 'failedSearch' && (
          <div className="flex flex-col items-center justify-center gap-m pt-s">
            <div className="block text-body1Regular text-neutral-800">
              {bodyText}
            </div>
            <div className="w-fit-content">
              <Button
                aria-label={ariaLabel}
                onClick={handleResetAction}
                variant={ButtonVariants.Tertiary}
              >
                Try again
              </Button>
            </div>
          </div>
        )}
      </div>
    </article>
  );
};

export default EmptySearchResult;
