import FormInput from '@/components/molecules/FormInput';
import React from 'react';

interface PriceRangeProps {
  minValue?: string;
  maxValue?: string;
  minLabel?: string;
  maxLabel?: string;
  showSlider?: boolean;
  handleMinInput?: (val: string) => void;
  handleMaxInput?: (val: string) => void;
  handleMinBlurChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMaxBlurChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showMin?: boolean;
  showMax?: boolean;
}

const PriceRange: React.FC<PriceRangeProps> = ({
  minValue,
  maxValue,
  minLabel,
  maxLabel,
  handleMaxInput = () => {},
  handleMinInput = () => {},
  handleMinBlurChange = () => {},
  handleMaxBlurChange = () => {},
  showMin = true,
  showMax = true,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-between space-x-[20px]">
        {showMin && (
          <FormInput
            type="text"
            className="w-full rounded"
            prepend={minValue ? '$' : ''}
            label={minLabel}
            value={minValue}
            onChange={handleMinInput}
            onBlur={handleMinBlurChange}
          />
        )}
        {showMax && (
          <FormInput
            type="text"
            className="!mr-0 w-full rounded"
            prepend={maxValue ? '$' : ''}
            label={maxLabel}
            value={maxValue}
            onChange={handleMaxInput}
            onBlur={handleMaxBlurChange}
          />
        )}
      </div>
    </div>
  );
};

export default PriceRange;
