import Checkbox, { CheckboxProps } from '@/components/atoms/legacy/Checkbox';
import Icon, { IconIds, IconProps } from '@/components/atoms/legacy/Icon';
import Label from '@/components/atoms/legacy/Label';
import React, { PropsWithChildren } from 'react';

export interface FormCheckboxProps extends CheckboxProps {
  label?: string;
  checkboxLabel: string | JSX.Element;
  invalid?: boolean;
  errorMessage?: string;
  checkBoxStyle?: string;
  icon?: IconProps;
}

const FormCheckbox: React.FC<PropsWithChildren<FormCheckboxProps>> = ({
  className = '',
  checkBoxStyle = '',
  label,
  checkboxLabel,
  invalid,
  errorMessage,
  onChange,
  icon,
  children,
  ...props
}) => {
  return (
    <Label className={`${className} cursor-pointer`}>
      <section className="flex items-center space-x-[6px] text-body1Light text-neutral-900">
        <Checkbox
          className={`${invalid ? 'error' : ''} ${checkBoxStyle}`}
          onChange={onChange}
          {...props}
        />
        <span className="whitespace-nowrap">{checkboxLabel}</span>
        {icon && <Icon {...icon} />}
        {children}
      </section>
      {errorMessage && (
        <span
          className={`error-message ${
            invalid ? 'flex' : ''
          } mt-[4px] items-center text-red-dark`}
        >
          <Icon
            className="mr-[8px] shrink-0 text-micro2"
            iconId={IconIds.Danger}
          />
          {errorMessage}
        </span>
      )}
    </Label>
  );
};

export default FormCheckbox;
