import Button, { ButtonVariants } from '@/components/atoms/legacy/Button';
import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import Input from '@/components/atoms/legacy/Input';
import copyToClipboard from '@/lib/copyToClipboard';
import { ScComponent } from '@/types/interfaces';
import React from 'react';

const ShareLinkModal: React.FC<ScComponent> = ({ className }) => {
  const [url, setUrl] = React.useState('');
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    setUrl(window.location.href);
  }, []);

  const copyUrlToClipboard = () => {
    copyToClipboard(window.location.href);
    setCopied(true);
  };

  return (
    <article className={`bg-blue-light p-[40px] ${className}`}>
      <Icon className="mb-[8px]" iconId={IconIds.Share} />
      <Heading
        className="mb-[8px] text-subHeadline2 text-blue-dark"
        level={HeadingLevels.H2}
      >
        Share with others
      </Heading>
      <p className="text-body1 text-blue-dark">
        Share EV vehicles with your social community. Click an app below or copy
        and paste the URL directly.
      </p>
      <section className="flex items-center justify-center space-x-[20px] p-[20px]">
        <a
          href={`mailto:?subject=Checkout this vehicle&body=${url}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Send an Email"
        >
          <Icon iconId={IconIds.SMSEdit} />
        </a>
        <a
          href={`//www.facebook.com/sharer/sharer.php?u=${url}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Share on Facebook"
        >
          <Icon
            className="[&>g>path]:fill-[#1877F2]"
            iconId={IconIds.Facebook}
          />
        </a>
        <a
          href={`//twitter.com/intent/tweet?url=${url}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Tweet on Twitter"
        >
          <Icon iconId={IconIds.Twitter} fillColor="#1DA1F2" />
        </a>
      </section>
      <Heading
        className="mb-[4px]text-body4 text-neutral-600"
        level={HeadingLevels.H3}
      >
        Or copy the link
      </Heading>
      <Input
        className="mb-[10px] block w-full"
        value={url}
        onChange={() => {}}
      />
      <Button
        className="block w-full"
        variant={ButtonVariants.Secondary}
        onClick={copyUrlToClipboard}
      >
        {!copied ? 'Copy Link' : 'Copied!'}
      </Button>
    </article>
  );
};

export default ShareLinkModal;
