import FormInput from '@/components/molecules/FormInput';
import { DEFAULT_US_ZIP_CODE } from '@/lib/constants';
import { State } from '@/lib/schema/geo/types';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef } from 'react';

export interface LocationAutoCompleteInputProps {
  zipCode?: string;
  setZip: (zip: string) => void;
  setAddress?: (address: string | undefined) => void;
  onBlur?: (zip: string) => void;
  onAutoComplete?: (zip: string, address: string) => void;
  message: string;
  useGeolocation: () => void;
  label?: string;
  invalid: boolean;
  isGooglePlacesLibraryLoaded: boolean;
}

const LocationAutoCompleteInput: React.FC<LocationAutoCompleteInputProps> = ({
  zipCode,
  setZip,
  setAddress,
  onBlur,
  onAutoComplete,
  message,
  useGeolocation,
  label = 'Location',
  invalid,
  isGooglePlacesLibraryLoaded,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const address1Field = inputRef.current?.querySelector('input');
    let autocomplete: google.maps.places.Autocomplete;

    const fetchPostalCode = async (state: string, city: string) => {
      let postcode = '';
      if (city && state) {
        const apiUrl = `https://api.zippopotam.us/us/${state}/${city}`;
        try {
          const response = await fetch(apiUrl);
          if (response.ok) {
            const data = await response.json();
            if (data.places && data.places.length > 0) {
              postcode = data.places[1]['post code'];
            }
          }
        } catch (error) {
          postcode = '';
        }
      } else if (!city && state) {
        const res = await fetch(`/api/geo/states/${state}`);
        if (res.ok) {
          const data: State = await res.json();
          postcode = data.postalCode;
        }
      } else {
        postcode = DEFAULT_US_ZIP_CODE;
      }
      return postcode;
    };

    const getPostalCodeFromPlace = (
      addresses: google.maps.GeocoderAddressComponent[]
    ) => {
      let postcode = '';
      let address = '';
      let city = '';
      let state = '';
      addresses.forEach((component) => {
        const componentType = component.types[0];
        switch (componentType) {
          case 'postal_code':
            postcode = component.short_name;
            break;
          case 'sublocality_level_1':
            address = `${component.short_name}, `;
            break;
          case 'neighborhood':
          case 'locality':
          case 'political':
          case 'natural_feature':
            city = component.long_name;
            if (!address) {
              address = `${component.long_name}, `;
            }
            break;
          case 'administrative_area_level_1':
            state = component.short_name;
            address += `${component.short_name}, `;
            break;
          case 'country':
            address += `${component.short_name}`;
            break;
          default:
            break;
        }
      });
      return { postcode, address, city, state };
    };

    const handleBlur = () => {
      const inputValue = address1Field?.value;

      if (!inputValue || !autocomplete) return;

      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: inputValue,
          componentRestrictions: { country: ['us', 'ca'] },
        },
        (predictions, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            predictions &&
            predictions.length > 0
          ) {
            const firstPrediction = predictions[0];

            const placesService = new google.maps.places.PlacesService(
              document.createElement('div')
            );

            placesService.getDetails(
              { placeId: firstPrediction.place_id },
              async (placeResult, placeStatus) => {
                if (
                  placeStatus === google.maps.places.PlacesServiceStatus.OK &&
                  placeResult
                ) {
                  if (placeResult.address_components) {
                    let finalZip = '';
                    const { postcode, address, city, state } =
                      getPostalCodeFromPlace(placeResult.address_components);

                    finalZip = postcode;
                    if (!finalZip) {
                      finalZip = await fetchPostalCode(state, city);
                    }
                    setZip(finalZip);
                    setAddress?.(address);
                    onAutoComplete?.(finalZip, address);
                    onBlur?.(finalZip);
                  }
                }
              }
            );
          }
        }
      );
    };

    if (isGooglePlacesLibraryLoaded) {
      if (address1Field) {
        autocomplete = new google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ['us', 'ca'] },
          fields: ['address_components'],
        });

        autocomplete.addListener('place_changed', async () => {
          const place = autocomplete.getPlace();

          if (place.address_components) {
            let finalZip = '';
            const { postcode, address, city, state } = getPostalCodeFromPlace(
              place.address_components
            );
            finalZip = postcode;
            if (!finalZip) {
              finalZip = await fetchPostalCode(state, city);
            }
            setZip(finalZip);
            setAddress?.(address);
            onAutoComplete?.(finalZip, address);
            onBlur?.(finalZip);
          }
        });
      }
    }

    if (address1Field) {
      address1Field.addEventListener('blur', handleBlur);
    }

    return () => {
      if (address1Field) {
        address1Field.removeEventListener('blur', handleBlur);
      }
    };
  }, [isGooglePlacesLibraryLoaded, setZip, setAddress, onBlur, onAutoComplete]);

  return (
    <section ref={inputRef}>
      <FormInput
        value={zipCode || ''}
        onChange={(zip) => {
          setZip(zip);
          setAddress?.(undefined);
        }}
        placeholder={'City or Zip Code'}
        label={label}
        errorMessage={message}
        invalid={invalid}
        icon={<MapPinIcon className="w-[20px] cursor-pointer" />}
        handleIconClick={useGeolocation}
      />
    </section>
  );
};

export default LocationAutoCompleteInput;
